import { fetchEmployees } from "@rpforms/shared/build/actions/employees";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import { push } from "connected-react-router";
import moment from "moment";
import "moment/locale/de";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "../../reducers";

export const VacationCalendar = () => {
    const employees = useSelector<RootState, any>((state) => state.employees.employees);
    const dispatch = useDispatch();
    const localizer = momentLocalizer(moment);
    const [showNoPermission, setShowNoPermission] = useState(false);

    useEffect(() => {
        dispatch(fetchEmployees());
    }, []);

    if (!employees) {
        return <Loader />;
    }

    const vacations = employees
        .map((e) =>
            [
                ...e.vacations.map((vacation) => ({
                    employee_id: e.id,
                    title: `${e.name} | Freigegeben: ${vacation.permitted ? "ja" : "nein"}`,
                    start: vacation.from,
                    end: vacation.to,
                    color: e.color,
                    permitted: vacation.permitted,
                })),
                ...e.employee_special_vacations.map((vacation) => ({
                    employee_id: e.id,
                    title: `${e.name} | ${vacation.extra_vac_name}`,
                    start: vacation.extra_vac_from,
                    end: vacation.extra_vac_to,
                    color: e.color,
                    permitted: true,
                })),
            ].filter((v) => !!v)
        )
        .flat();

    const onSelectEvent = (event) => {
        dispatch(push(`/master_data/employees/view-${event.employee_id}`));
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        var style = {
            backgroundColor: event?.color || "#3174ad",
            borderRadius: "0px",
            opacity: 0.8,
            color: "white",
            border: "0px",
            display: "block",
        };
        return {
            style: style,
        };
    };

    return (
        <>
            <div className="row pb-5">
                <div className="col d-flex flex-column align-items-start">
                    <label>Auch noch nicht genehmigte Urlaube anzeigen?</label>
                    <input
                        type="checkbox"
                        onChange={(e) => setShowNoPermission(e.target.checked)}
                    />
                </div>
            </div>
            <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 1000 }}
                eventPropGetter={eventStyleGetter}
                views={["month", "agenda"]}
                messages={{
                    date: "Datum",
                    time: "Zeit",
                    month: "Monat",
                    week: "Woche",
                    day: "Tag",
                    today: "Heute",
                    previous: "Zurück",
                    next: "Weiter",
                    noEventsInRange: "Es gibt keine Termine in diesem Zeitraum.",
                }}
                onSelectEvent={onSelectEvent}
                events={showNoPermission ? vacations : vacations.filter((v) => v.permitted)}
            />
        </>
    );
};

export default withRouter(VacationCalendar);
