import { createEditCalendarEvent, regeneratePDF } from "@rpforms/shared";
import {
    getFolderLocation,
    updateFormEntry,
    updateFormEntryStatus,
} from "@rpforms/shared/build/actions";
import * as actions from "@rpforms/shared/build/actions/formEntries";
import { SmallButton, SmallPrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import { push } from "connected-react-router";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Modal, Spinner } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { useSearchParam } from "react-use";
import styled from "styled-components";
import { API_URL } from "../../config";
import { ListRow } from "../forms/FormListRow";
import EntryRowOptions from "./EntryRowOptions";
import EntryRowState from "./EntryRowState";
import { FormName } from "./FormEntriesRow";

const LoadingOverlay = styled.div`
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    inset: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
`;

export type FormEntryState =
    | "open"
    | "processed"
    | "accepted"
    | "paid"
    | "rejected"
    | "reminded"
    | "canceled"
    | "offer_needed";

const EntryRow = ({ entry, deleteFormEntry }) => {
    const previousPage = parseInt(useSearchParam("page"), 10) || 1;
    const [renaming, setRenaming] = useState(false);
    const [show, setShow] = useState(false);
    const { calendarEvents, isLoading } = useSelector<any, any>((state) => state.calendarEvents);
    const [editEntryId, setEntryEditId] = useState(null);
    const { regeneratingPDF } = useSelector<any, any>((state) => state.formEntries);

    const dispatch = useDispatch();
    const renamingField = useRef();
    const entryDate = moment(new Date(entry.created_at)).format("DD.MM.YYYY HH:mm");
    const stateUpdatedAt = entry.human_state_updated_at || "";
    const ipadName = entry.device_name;
    const handleClose = () => {
        setShow(false);
    };

    useEffect(() => {
        if (isLoading || editEntryId !== entry.id) {
            return;
        }
        setEntryEditId(null);
        if (calendarEvents.length === 0) {
            toast.error(
                "Dem Formular konnte kein Gerät zugeordnet werden, " +
                    "bitte erstellen Sie die Nachbearbeitung manuell im Kalender"
            );
            return;
        }
        toast.success("Nachbearbeitungsauftrag wurde erfolgreich erstellt");
    }, [isLoading]);

    const deleteEntry = () => {
        if (confirm("Sind Sie sicher?")) {
            deleteFormEntry(entry);
        }
    };

    // creates a new deviceTask of type 'Nachbearbeitung'
    const createEditTaskClick = (entryId: number) => {
        dispatch(createEditCalendarEvent(entryId));
        setEntryEditId(entryId);
    };

    const renameEntry = () => {
        setRenaming(!renaming);
    };

    const saveEntry = async () => {
        if (!renamingField?.current?.value) {
            return;
        }
        setRenaming(false);
        await dispatch(
            updateFormEntry({
                ...entry,
                name: renamingField?.current?.value,
            })
        );
    };

    const updateState = async (id, state, terms) => {
        await dispatch(updateFormEntryStatus(id, state, terms));
    };

    const openInFolder = async () => {
        const req = await dispatch(
            getFolderLocation({
                resource_id: entry.id,
                resource_type: "FormEntry",
            })
        );
        dispatch(push("/folders?open=" + encodeURIComponent(JSON.stringify(req.data))));
    };

    const migrate = async () => {
        // dispatch(push("/entries/editor/migrate/source/" + entry.id + "/target/" + entry.form.id));
        window.location.assign(
            "/entries/editor/migrate/source/" + entry.id + "/target/" + entry.form.id
        );
    };

    const template = async () => {
        dispatch(push("/entries/editor/template/source/" + entry.id + "/target/" + entry.form.id));
    };
    const regenerate = async () => {
        dispatch(regeneratePDF(entry.id));
    };

    const pdf = entry.pdf;

    return (
        <>
            <ListRow data-id={entry.id}>
                <div>
                    <FormName>
                        {!renaming && entry.name}
                        {renaming && (
                            <>
                                <input
                                    type="text"
                                    ref={renamingField}
                                    className="form-control"
                                    defaultValue={entry.name}
                                />
                                <SmallButton onClick={saveEntry}>
                                    <i className={"fa fa-check"}></i>
                                </SmallButton>
                                <SmallButton onClick={renameEntry}>
                                    <i className={"fa fa-close"}></i>
                                </SmallButton>
                            </>
                        )}
                    </FormName>
                    <small>
                        {entry.terms && entry.terms.length > 0 && (
                            <>
                                {entry.terms.map((term) => {
                                    return (
                                        <Badge
                                            key={`${entry.id}_${term.id}`}
                                            className="mx-1"
                                            style={{
                                                backgroundColor:
                                                    term.name === "Wartungsvertrag"
                                                        ? "#777"
                                                        : term.options.backgroundColor,
                                                color: term.options.color,
                                            }}
                                        >
                                            {term.name}
                                        </Badge>
                                    );
                                })}
                                &nbsp;
                            </>
                        )}
                        {entry.entity && (
                            <>
                                Objekt: <strong>{entry.entity.name}</strong>|{" "}
                            </>
                        )}
                        Formular: <strong>{entry.form.name}</strong>| Info:{" "}
                        <strong>{entryDate}</strong> | iPad: <strong>{ipadName}</strong>
                        {entry.meta && entry.meta.summary && (
                            <>
                                <br />
                                {entry.meta.summary}
                            </>
                        )}
                    </small>
                </div>

                {regeneratingPDF === entry.id && (
                    <LoadingOverlay>
                        <Spinner animation={"border"} size={"sm"} /> <span>&nbsp;</span> PDF wird
                        generiert
                    </LoadingOverlay>
                )}

                <RightAligned>
                    <EntryRowOptions
                        {...{
                            entry,
                            previousPage,
                            migrate,
                            regenerate,
                            template,
                            setShow,
                            deleteEntry,
                            createEditTaskClick,
                        }}
                    />
                    {!pdf && !entry.pdf && process.env.NODE_ENV !== "development" && (
                        <SmallButton disabled>
                            <i className="fa fa-file-pdf-o"></i> Erstelle PDF...
                        </SmallButton>
                    )}
                    {pdf && (
                        <a target="_blank" href={`${API_URL}/pdf/${entry.id}`}>
                            <SmallButton>
                                <i className="fa fa-file-pdf-o"></i> PDF
                            </SmallButton>
                        </a>
                    )}

                    {!pdf && entry.pdf && (
                        <a href={`${entry.pdf}`} target={"_blank"}>
                            <SmallButton>
                                <i className="fa fa-file-pdf-o"></i> PDF
                            </SmallButton>
                        </a>
                    )}

                    <SmallPrimaryButton onClick={openInFolder}>
                        <i className="fa fa-folder-open" /> Ordnerstruktur
                    </SmallPrimaryButton>

                    <EntryRowState
                        stateUpdatedAt={stateUpdatedAt}
                        state={entry.state}
                        humanState={entry.human_state}
                        updateState={updateState}
                        formGroupName={entry.form?.form_group?.name}
                        id={entry.id}
                        possibleEvents={entry?.possible_events || []}
                    />
                </RightAligned>
            </ListRow>
            {show && (
                <Modal
                    size="lg"
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        {entry.uploads.map((upload) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>{upload.name || upload.unique_id} </div>
                                    <div>
                                        <a
                                            target="_blank"
                                            href={`${API_URL}/uploads/${upload.id}.json?redirect=true`}
                                        >
                                            <SmallButton>Ansehen</SmallButton>
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                        {entry.attachments.map((attachment) => {
                            if (!attachment || !attachment.id) {
                                return <div></div>;
                            }
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        {attachment.name}{" "}
                                        <small
                                            style={{
                                                color: "#aaa",
                                                fontSize: "10px",
                                            }}
                                        >
                                            [{attachment.id && attachment.id.substr(11, 8)}]
                                        </small>
                                    </div>
                                    <div>
                                        <a
                                            target="_blank"
                                            href={`${API_URL}/uploads/${attachment.id}.json?redirect=true`}
                                        >
                                            <SmallButton>Ansehen</SmallButton>
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Schließen
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

function mapStateToProps(state) {
    return {};
}

export default withRouter(connect(mapStateToProps, actions)(EntryRow));
