import { NEW_UPLOADS } from "@rpforms/shared";
import {
    addFolderToSelection,
    CUSTOMER_NEW_ENTITY,
    CUSTOMER_UPDATE_ENTITY,
    removeFolderFromSelection,
    UPDATE_CUSTOMER,
} from "@rpforms/shared/build/actions";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { translucentColor } from "@rpforms/shared/build/utils";
import { push } from "connected-react-router";
import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { RootState } from "../../reducers";
const FileIpad = "/file-ipad.svg";
const FilePdf = "/file-pdf.svg";
const FilePdfUpload = "/file-pdf-upload.svg";
const FilePng = "/file-png.svg";
const FileJpeg = "/file-jpeg.svg";

import { FolderContextMenu } from "./FolderContextMenu";
import { FolderIcon } from "./FolderIcon";

export const FolderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 20px;
    cursor: pointer;
    align-items: center;
    width: 150px;
    height: 150px;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    ${(props) =>
        props.isSelected &&
        css`
            background: ${translucentColor(props.theme.primary_color, 0.2)} !important;
        `}
`;

export const FolderTitle = styled.div`
    font-size: 14px;
    text-align: center;
    max-width: 100px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
`;

export const Folder = ({
    activeFolder,
    folder,
    onMove,
    onClick,
    movable,
    isSelected = false,
    isNote = false,
}) => {
    const dispatch = useDispatch();
    const { selection, selectionEnabled, search } = useSelector<RootState, any>(
        (state) => state.directory
    );
    const editEntity = (entity: Partial<IEntity>) => {
        dispatch(
            openEditor({
                endpoint: `entities`,
                endpointCompany: true,
                new: !entity.id,
                preload: { ...entity, address: entity.address || {} },
                type: !entity.id ? CUSTOMER_NEW_ENTITY : CUSTOMER_UPDATE_ENTITY,
            })
        );
    };

    const editCustomer = (customer: Partial<ICustomer>) => {
        dispatch(
            openEditor({
                endpoint: `customers`,
                endpointCompany: true,
                new: false,
                preload: customer,
                type: UPDATE_CUSTOMER,
            })
        );
    };

    const uploadModal = (f: Partial<IFolder>) => {
        dispatch(
            openEditor({
                endpoint: `folders/${f.id}/upload`,
                endpointCompany: false,
                new: true,
                preload: { folder: f },
                type: NEW_UPLOADS,
            })
        );
    };

    const contextActions = {
        open: onClick,
        edit: () => {
            if (folder.resource_type === "Customer") {
                editCustomer(folder.resource);
            }

            if (folder.resource_type === "Entity") {
                editEntity(folder.resource);
            }

            if (folder.resource.form_entry_id) {
                dispatch(push("/entries/editor/entry-edit-" + folder.resource.form_entry_id));
            }
            if (folder.uploadable) {
                uploadModal(folder);
            }
        },
        move: onMove,
    };

    const toggleSelection = ({ currentTarget, stopPropagation, preventDefault }) => {
        if (currentTarget.checked) {
            dispatch(addFolderToSelection(folder));
        } else {
            dispatch(removeFolderFromSelection(folder));
        }

        try {
            stopPropagation();
            preventDefault();
        } catch (e) {}

        return false;
    };

    /* verschieben/migrieren */
    const folderMovable = folder.movable;
    const folderMergable = folder.mergable;
    const folderSelectable = folderMovable || folderMergable;

    const folderName = () => {
        const name = folder.name || folder.resource?.name;
        if (!selectionEnabled) {
            return name;
        }
        if ((movable && folderMovable) || folderMergable) {
            return (
                <strong
                    style={{
                        display: "inline-block",
                        width: "500px",
                        textAlign: "left",
                    }}
                >
                    {name}
                </strong>
            );
        }
        return name;
    };

    const termNames = () => {
        if (!folder.terms || folder.terms.length <= 0) {
            return <></>;
        }
        return folder.terms.map((term) => {
            return (
                <Badge
                    key={`${folder.id}_${term.id}`}
                    className="mx-1"
                    style={{
                        backgroundColor:
                            term.name === "Wartungsvertrag" ? "#777" : term.options.backgroundColor,
                        color: term.options.color,
                    }}
                >
                    {term.name}
                </Badge>
            );
        });
    };

    const contactNames = () => {
        if (search.scope !== "contact") {
            return;
        }

        if (folder.resource_type !== "Entity") {
            return;
        }

        const contacts = [folder.resource.contact_name, folder.resource.timing_contact_name]
            .filter((x) => !!x)
            .map((x, type) => {
                const toolTip = (
                    <Tooltip id={"tooltip"}>
                        {type === 0 ? "Ansprechpartner" : "Ansprechpartner Terminierung"}
                    </Tooltip>
                );
                return (
                    <OverlayTrigger overlay={toolTip}>
                        <Badge
                            style={{
                                backgroundColor: "#b2d5e5",
                                color: "",
                            }}
                            className={"mx-1"}
                        >
                            {x}
                        </Badge>
                    </OverlayTrigger>
                );
            });
        return <>{contacts}</>;
    };

    const actionable = () => {
        return (
            folder.uploadable ||
            folder.editable ||
            folder.migratbale ||
            folder.viewable ||
            ["Customer", "Entity", "FormEntry"].includes(folder.resource_type) ||
            ["Entity"].includes(folder.parent_resource_type)
        );
    };

    const isJpeg = folder.resource
        ? folder.resource.extension === ".jpeg" ||
          folder.resource.mime_type === "image/jpeg" ||
          folder.name.includes(".jpeg") ||
          folder.name.includes(".jpg")
        : false;
    const isPng = folder.resource
        ? folder.resource.extension === ".png" ||
          folder.resource.mime_type === "image/png" ||
          folder.name.includes(".png")
        : false;

    return (
        <FolderWrapper
            onClick={() => onClick(folder)}
            isSelected={activeFolder.id === folder.id || isSelected}
            data-id={folder.id}
            data-folder={folder.id}
            data-selected={!!(activeFolder.id === folder.id || isSelected)}
        >
            <ContextMenuTrigger id={`folder-${folder.id}`}>
                <div style={{ display: "flex" }}>
                    {selectionEnabled && folderSelectable && (
                        <input onChange={toggleSelection} type="checkbox" />
                    )}
                    {isNote && (
                        <img
                            width={"24px"}
                            style={{ width: "24px" }}
                            height={"24px"}
                            src={FilePdf}
                        />
                    )}
                    {folder.resource_type !== "Upload" && (
                        <>
                            <FolderIcon
                                home={folder.resource_type === "Entity" ? "on" : undefined}
                                document={folder.resource_type === "Form" ? "on" : undefined}
                                user={folder.resource_type === "Customer" ? "on" : undefined}
                                task={folder.resource_type === "DeviceTask" ? "on" : undefined}
                            />

                            <FolderTitle id={`folder-${folder.id}`}>
                                {folderName()} {termNames()} {contactNames()}
                            </FolderTitle>
                        </>
                    )}

                    {folder.resource_type === "Upload" && (
                        <>
                            {isPng && (
                                <img
                                    width={"24px"}
                                    style={{ width: "24px" }}
                                    height={"24px"}
                                    src={FilePng}
                                />
                            )}
                            {isJpeg && (
                                <img
                                    width={"24px"}
                                    style={{ width: "24px" }}
                                    height={"24px"}
                                    src={FileJpeg}
                                />
                            )}
                            {!isPng && !isJpeg && folder.resource.ipad && (
                                <img
                                    width={"24px"}
                                    style={{ width: "24px !important", height: "24px !important" }}
                                    height={"24px"}
                                    src={FileIpad}
                                />
                            )}
                            {!isPng &&
                                !isJpeg &&
                                !folder.resource.ipad &&
                                (folder.resource.extension === ".pdf" ||
                                    folder.resource.mime_type === "application/pdf") &&
                                (folder.form_entry_id === null ? (
                                    <img
                                        width={"24px"}
                                        style={{ width: "24px" }}
                                        height={"24px"}
                                        src={FilePdf}
                                    />
                                ) : (
                                    <img
                                        width={"24px"}
                                        style={{ width: "24px" }}
                                        height={"24px"}
                                        src={FilePdfUpload}
                                    />
                                ))}
                            <FolderTitle>
                                {" "}
                                {folder.name} {termNames()}
                            </FolderTitle>
                        </>
                    )}
                </div>
            </ContextMenuTrigger>

            {actionable() && <FolderContextMenu actions={contextActions} folder={folder} />}
        </FolderWrapper>
    );
};
