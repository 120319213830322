import { getCalendarEvents } from "@rpforms/shared/build/actions";
import { getDevices } from "@rpforms/shared/build/actions/devices";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import moment from "moment";
import "moment/locale/de";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../../reducers";
import CalendarEventTypeSelector from "../../calendar/CalendarEventTypeSelector";

export const EventCalendar = () => {
    const calendarEventState = useSelector<any, any>((state) => state.calendarEvents);
    const events = useSelector<any, any>((state) => state.calendarEvents.calendarEvents);
    const deviceState = useSelector<any, any>((state) => state.device);
    const types = useSelector<RootState, any>((store) => store.calendarEventTypes.types);

    const dispatch = useDispatch();
    const localizer = momentLocalizer(moment);
    const [showSubmittedEvents, setShowSubmittedEvents] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(0);
    const [selectedType, setSelectedType] = useState(null);
    const [range, setRange] = useState({
        start: moment(+new Date()).startOf("week"),
        end: moment(+new Date()).endOf("week"),
    });

    const handleFilters = (events: any) => {
        let calendarEvents = events;
        if (selectedType && selectedType.id !== -1) {
            const ausfuehrungsId = types.find((t) => t.name === "Ausführung")?.id;
            // handle old events without id
            if (ausfuehrungsId && selectedType.id === ausfuehrungsId) {
                calendarEvents = calendarEvents.filter(
                    (e) => e.calendar_event_type_id === ausfuehrungsId || !e.calendar_event_type_id
                );
            } else {
                calendarEvents = calendarEvents.filter(
                    (e) => e.calendar_event_type_id === selectedType.id
                );
            }
        }
        if (!showSubmittedEvents) {
            calendarEvents = calendarEvents.filter((e) => !e.was_submitted);
        }
        if (selectedDevice !== 0) {
            calendarEvents = calendarEvents.filter((event) => event.device_id === selectedDevice);
        }
        return calendarEvents;
    };

    useEffect(() => {
        dispatch(getDevices());
    }, []);

    useEffect(() => {
        dispatch(getCalendarEvents(range));
    }, [range]);

    const devices = deviceState.devices;

    const mappedDevicesForSelect = [
        { value: 0, label: "Alle" },
        { value: null, label: "Ohne Gerät" },
    ].concat(
        devices.map((device) => {
            return { value: device.id, label: device.name };
        })
    );

    const eventStyleGetter = (event, start, end, isSelected) => {
        const ausführungsId = types.find((t) => t.name === "Ausführung")?.id;
        const type = types.find((t) => t.id === event.calendar_event_type_id);
        const isAusführung =
            event.calendar_event_type_id === ausführungsId || !event.calendar_event_type_id;
        const device = devices.find((d) => event.device_id === d.id);

        const style = {
            backgroundColor: !isAusführung ? type.color : device?.calendar_color || "#3174ad",
            borderRadius: "0px",
            opacity: 0.8,
            color: "white",
            fontWeight: event.was_submitted ? "normal" : "bold",
            border: "0px",
            display: "flex",
            fontSize: "13px",
            fontStyle: event.was_submitted ? "italic" : "normal",
        };
        return {
            style,
        };
    };

    const onSelectEvent = (event) => {
        window.location.href = event.was_submitted
            ? `/master_data/devices/view-${event.device_id}/event-${event.id}?submitted=true`
            : `/master_data/devices/view-${event.device_id}/event-${event.id}`;
    };
    const setNewRange = (l) => {
        setRange({
            start: moment(l[0]).startOf("day"),
            end: moment(l[l.length - 1]).endOf("day"),
        });
    };

    return (
        <>
            <Row className="">
                <Col>
                    <label>Wählen Sie einen Eventtypen aus</label>
                    <CalendarEventTypeSelector
                        types={[
                            {
                                id: -1,
                                name: "Alle",
                                color: "rgba(0,0,0,0)",
                            },
                            ...types,
                        ]}
                        eventType={selectedType}
                        setFirstAsDefault
                        setEventType={setSelectedType}
                    />
                </Col>
                <Col>
                    <label>Wählen Sie die Geräte aus, die angezeigt werden sollen</label>
                    <Select
                        options={mappedDevicesForSelect}
                        defaultValue={mappedDevicesForSelect[0]}
                        onChange={(selected) => setSelectedDevice(selected.value)}
                        styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                    />
                </Col>
            </Row>
            <div className="row pb-5 pt-1">
                <div className="col d-flex flex-column align-items-start">
                    <label>Auch bereits abgeschlossene Events anzeigen?</label>
                    <input
                        type="checkbox"
                        onChange={(e) => setShowSubmittedEvents(e.target.checked)}
                    />
                </div>
            </div>
            {calendarEventState.isLoading || deviceState.isLoading || !types ? (
                <div style={{ position: "absolute" }}>
                    <Spinner animation="border" size={"lg"} variant={"primary"} />
                </div>
            ) : (
                <></>
            )}
            <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 1000 }}
                onSelectEvent={onSelectEvent}
                onRangeChange={(range) => setNewRange(range)}
                eventPropGetter={eventStyleGetter}
                defaultView={"week"}
                messages={{
                    date: "Datum",
                    time: "Zeit",
                    month: "Monat",
                    week: "Woche",
                    day: "Tag",
                    today: "Heute",
                    previous: "Zurück",
                    next: "Weiter",
                    noEventsInRange: "Es gibt keine Termine in diesem Zeitraum.",
                }}
                events={handleFilters(events).map((event: any) => {
                    return {
                        ...event,
                        title: event.calendar_title,
                        start: moment(event.scheduled_from).toDate(),
                        end: moment(event.scheduled_to).toDate(),
                        allDay: false,
                    };
                })}
            />
        </>
    );
};

export default EventCalendar;
