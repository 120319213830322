import React, { useState } from "react";
import { Alert, Dropdown, Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { FormEntryState } from "./EntryRow";

const DropdownContainer = styled.div`
    position: relative;
`;

const StatusDate = styled.div`
    position: absolute;
    padding-top: 5px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #929292;
`;

function setVariant(state: FormEntryState) {
    if (state === "processed" || state === "accepted" || state === "paid") {
        return "success";
    }
    if (state === "rejected" || state === "canceled") {
        return "danger";
    }
    if (state === "reminded") {
        return "warning";
    }
    return "secondary";
}

function mapToHumanState(event) {
    switch (event) {
        case "switch_to_open":
            return "Offen";
        case "process":
            return "Durchgeführt";
        case "accept":
            return "Akzeptiert";
        case "pay":
            return "Bezahlt";
        case "reject":
            return "Abgelehnt";
        case "remind":
            return "Offen & Mahnung";
        case "cancel":
            return "Storniert";
        case "need_offer":
            return "Angebot nötig";
        default:
            return "--";
    }
}

export default function EntryRowStatus({
    stateUpdatedAt,
    state,
    humanState,
    updateState,
    formGroupName,
    id,
    possibleEvents,
}) {
    const [showMaterialAlert, setShowMaterialAlert] = useState(false);
    const [showMaterialConfirm, setShowMaterialConfirm] = useState(false);
    const updateStateWithId = async (tmpState: string) => {
        if (formGroupName === "Angebote" && tmpState === "accept") {
            setShowMaterialConfirm(true);
        } else {
            await updateState(id, tmpState);
        }
        if (formGroupName === "Aufträge (sichtbar)" && tmpState === "accept") {
            setShowMaterialAlert(true);
        }
    };
    const acceptWithoutTag = async () => {
        await updateState(id, "accept");
        setShowMaterialConfirm(false);
    };
    const acceptWithTag = async () => {
        await updateState(id, "accept", ["Ersatzteile betsellt"]);
        setShowMaterialConfirm(false);
    };
    return (
        <DropdownContainer>
            <Dropdown>
                <Dropdown.Toggle
                    variant={setVariant(state)}
                    id="dropdown-basic"
                    size="sm"
                    style={{ width: "150px" }}
                    disabled={!formGroupName || possibleEvents?.length === 0}
                >
                    {humanState}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {possibleEvents.map((event) => (
                        <Dropdown.Item onClick={() => updateStateWithId(event)} key={event}>
                            {mapToHumanState(event)}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <StatusDate>{stateUpdatedAt}</StatusDate>
            <Modal
                show={showMaterialConfirm}
                onHide={() => {
                    setShowMaterialConfirm(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ersatzteile</Modal.Title>
                </Modal.Header>
                <Modal.Body>Müssen Ersatzteile bestellt werden?</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowMaterialConfirm(false);
                        }}
                    >
                        Abbrechen
                    </Button>
                    <Button variant="success" onClick={acceptWithoutTag}>
                        Nein
                    </Button>
                    <Button variant="danger" onClick={acceptWithTag}>
                        Ja
                    </Button>
                </Modal.Footer>
            </Modal>
            <Alert
                style={{
                    position: "fixed",
                    top: "0px",
                    zIndex: 999,
                    left: "0px",
                    right: "0px",
                }}
                show={showMaterialAlert}
                variant="success"
            >
                <Alert.Heading>Materialbestellung notwendig?</Alert.Heading>
                <p>
                    Mit dem Akzeptieren eines Angebots wird automatisch ein Event in der{" "}
                    <Alert.Link href="/calendar/planning">Vorausplanung</Alert.Link> erstellt. Wenn
                    weiteres Material benötigt wird, um den neuen Auftrag umzusetzen, sollten Sie
                    dieses so bald wie möglich bestellen. Falls kein weiteres Material benötigt
                    wird, können Sie den Materialstandort im neu entstandenen Event angeben und
                    einen Zeitraum für dieses festlegen.
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setShowMaterialAlert(false)} variant="outline-success">
                        Verstanden
                    </Button>
                </div>
            </Alert>
        </DropdownContainer>
    );
}
