import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { FINANCES_CHANGE_INVOICE_TYPE, getRevenues } from "@rpforms/shared";
import { RootState } from "../../reducers";
import { FinancesType } from "../../pages/finances/FinancesPage";

interface ButtonProps {
    active: boolean;
}
interface ContainerProps {
    justify?: string;
}

export const FinancesButtonContainer = styled.div<ContainerProps>`
    display: flex;
    justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
    align-items: center;
`;

export const FinancesSelectorButton = styled.button<ButtonProps>`
    color: #000;
    padding: 0.5rem 2rem;
    border: 1px solid #949494;
    margin: 0 0.2rem 0 0;
    background-color: ${(props) => (props.active ? "#f1f1f1" : "#fff")};
    position: relative;
`;

const FinancesInvoiceTypeSelector: React.FC<{}> = ({ maxDate }) => {
    const [customerId, setCustomerId] = useState(null);
    const [entityId, setEntityId] = useState(null);
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [iType, setIType] = useState(0);

    const {
        customer_id,
        entity_id,
        invoiceType,
        offerType: oTypeRedux,
        selectedYear,
        selectedMonth,
        selectedDay,
    } = useSelector<RootState, any>((state) => state.finances);

    useEffect(() => {
        setCustomerId(customer_id);
        setEntityId(entity_id);
        setYear(selectedYear || new Date().getFullYear());
        setMonth(selectedMonth);
        setDay(selectedDay);
        setIType(invoiceType);
    }, [customer_id, entity_id, selectedYear, selectedMonth, selectedDay, invoiceType]);

    const dispatch = useDispatch();

    const handleClick = (buttonId, offerType) => {
        dispatch({ type: FINANCES_CHANGE_INVOICE_TYPE, payload: { buttonId, offerType } });

        dispatch(
            getRevenues(
                offerType,
                customerId,
                entityId,
                year || new Date().getFullYear(),
                month,
                day,
                FinancesType.INVOICE
            )
        );
    };

    return (
        <FinancesButtonContainer style={{ margin: "2rem 0" }}>
            <FinancesSelectorButton active={iType === 0} onClick={() => handleClick(0, null)}>
                Gesamt
            </FinancesSelectorButton>
            <FinancesSelectorButton active={iType === 1} onClick={() => handleClick(1, "disposal")}>
                Verkauf
            </FinancesSelectorButton>
            <FinancesSelectorButton active={iType === 2} onClick={() => handleClick(2, "repair")}>
                Reparatur
            </FinancesSelectorButton>
            <FinancesSelectorButton active={iType === 3} onClick={() => handleClick(3, "service")}>
                Service
            </FinancesSelectorButton>
        </FinancesButtonContainer>
    );
};

export default FinancesInvoiceTypeSelector;
