import {
    fetchCustomersForAutocomplete,
    folderNameRegex,
    positiveNumberKeyDownEvent,
} from "@rpforms/shared";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import { InputError } from "@rpforms/shared/build/components/layout/Widget";
import { highestCustomerNr, padIncrement } from "@rpforms/shared/build/utils";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import { useDispatch } from "react-redux";
import { ModalAddressForm } from "./ModalAddressForm";

interface IModalEntity {
    editor: IEditor;
    next: (payload) => {};
}

const STARTING_CUSTOMERS_NR = "00001";
export const ModalCustomer = ({ editor, next }: IModalEntity) => {
    const [extraArgs, setExtraArgs] = useState({});
    const [customer, setCustomer] = useState<ICustomer>({ ...editor.config.preload });
    const [taggedAsDeleted, setTaggedAsDeleted] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const dispatch = useDispatch();

    const delInTags = () => {
        return customer.terms && customer.terms.filter((t) => t.name === "Gekündigt").length > 0;
    };

    useEffect(() => {
        setTaggedAsDeleted(delInTags);
        if (customer.customer_nr) {
            return;
        }
        const loadCustomerNr = async () => {
            const customers: any = await dispatch(fetchCustomersForAutocomplete());
            const nextCustomerNr = customers.length
                ? padIncrement(highestCustomerNr(customers))
                : STARTING_CUSTOMERS_NR;
            setCustomer({
                ...customer,
                customer_nr: "K" + nextCustomerNr,
            });
        };
        loadCustomerNr();
    }, []);

    const onSubmit = async (data) => {
        const customerStore: any = await next({
            customer: { ...editor.config.preload, ...data, ...extraArgs },
        });
        if (editor.config.redirect) {
            await dispatch(push("/master_data/customers/view-" + customerStore.id));
        }
    };

    const isNew = editor.config.new;

    // Map ReactHookForm errors to regular errors
    const addressErrors = {
        street: errors["address_attributes.street"],
        city: errors["address_attributes.city"],
        country: errors["address_attributes.country"],
        postalcode: errors["address_attributes.postalcode"],
        extension: errors["address_attributes.extension"],
        contact: errors["address_attributes.contact"],
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{isNew ? "Neuer Kunde erstellen" : "Kunde bearbeiten"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <label htmlFor={`customer-${customer.id}-name`}>Name</label>
                        <input
                            id={`customer-${customer.id}-name`}
                            defaultValue={customer.name}
                            className={"form-control"}
                            name="name"
                            ref={register({
                                required: "Name muss ausgefüllt sein",
                                pattern: {
                                    value: folderNameRegex(),
                                    message: "Name muss mit Buchstabe oder Zahl enden",
                                },
                            })}
                        />
                        {errors.name && <InputError>{errors.name.message}</InputError>}
                    </Col>
                    <Col>
                        <label htmlFor={`customer-${customer.id}-customer_nr`}>Kundennummer</label>
                        <input
                            id={`customer-${customer.id}-customer_nr`}
                            defaultValue={customer.customer_nr}
                            className={"form-control"}
                            name="customer_nr"
                            readOnly={true}
                            ref={register({
                                required: "Kundennummer muss ausgefüllt werden.",
                                pattern: {
                                    value: /^K[0-9]{4,5}$/i,
                                    message: "Kundennummer muss folgendes Format haben: K1111",
                                },
                            })}
                        />
                        {errors.customer_nr && (
                            <InputError>{errors.customer_nr.message}</InputError>
                        )}
                    </Col>
                    <Col>
                        <label htmlFor={`customer-${customer.id}-billing_email`}>
                            E-Mail für Rechnungen
                        </label>
                        <input
                            id={`customer-${customer.id}-billing_email`}
                            defaultValue={customer.billing_email}
                            className={"form-control"}
                            name="billing_email"
                            readOnly={false}
                            ref={register({})}
                        />
                        {errors.billing_email && (
                            <InputError>{errors.billing_email.message}</InputError>
                        )}
                    </Col>
                </Row>
                {isNew && (
                    <ModalAddressForm
                        prefix={"address_attributes."}
                        address={{}}
                        register={register}
                        errors={addressErrors}
                    />
                )}
                <Row>
                    <Col>
                        <label htmlFor={`customer-${customer.id}-discount`}>Rabatt</label>
                        <InputGroup style={{ flexWrap: "inherit" }}>
                            <input
                                id={`customer-${customer.id}-discount`}
                                className={"form-control"}
                                name="discount"
                                type="number"
                                defaultValue={customer.discount}
                                ref={register({
                                    required: "Rabatt muss ausgefüllt sein",
                                    pattern: {
                                        value: /\d{1,3}/,
                                        message: "Wert muss numerisch und kleiner als 100 sein",
                                    },
                                })}
                                min="0"
                                onKeyDown={positiveNumberKeyDownEvent}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col style={{ marginTop: "auto" }}>
                        <Form.Check type="checkbox" id={"entity-terms"}>
                            <Form.Check.Input
                                type="checkbox"
                                checked={taggedAsDeleted}
                                onChange={(ev) => {
                                    setTaggedAsDeleted(!delInTags());
                                    const terms = delInTags() ? [] : [{ name: "Gekündigt" }];
                                    setCustomer({
                                        ...customer,
                                        terms,
                                    });
                                    setExtraArgs({
                                        ...extraArgs,
                                        terms,
                                    });
                                }}
                            />
                            <Form.Check.Label className={"mt-0"}>
                                <strong>als „Gekündigt“ markieren</strong>
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {editor.saving && <>speichert …</>}
                <PrimaryButton
                    disabled={editor.saving}
                    style={editor.saving ? { background: "gray" } : {}}
                    type={"submit"}
                >
                    {isNew ? "Erstellen" : "Speichern"}
                </PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
