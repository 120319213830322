import React, { useState } from "react";
import styled from "styled-components";
import { API_URL } from "../../config";
import { DangerButton, SmallPrimaryButton } from "../Button";
import PageNavAction from "../universal/PageNavAction";
import { LocalUploadPreview } from "./LocalUploadPreview";
import { UploadPreview } from "./UploadPreview";

export const UploadsList = ({ uploads, onEditUpload, onDeleteUpload }: any) => {
    const [activeUploadIndex, setActiveUploadIndex] = useState(null);
    return (
        <>
            {activeUploadIndex !== null && (
                <CustomModal>
                    {activeUploadIndex !== 0 && (
                        <>
                            <PrevButton onClick={() => setActiveUploadIndex(activeUploadIndex - 1)}>
                                <i className="fa fa-arrow-left" /> Vorheriges Bild
                            </PrevButton>
                        </>
                    )}
                    {activeUploadIndex !== uploads.length - 1 && (
                        <NextButton
                            style={{ zIndex: 999 }}
                            onClick={() => setActiveUploadIndex(activeUploadIndex + 1)}
                        >
                            <i className="fa fa-arrow-right" /> Nächstes Bild
                        </NextButton>
                    )}
                    <DangerButton onClick={() => setActiveUploadIndex(null)}>X</DangerButton>
                    {uploads[activeUploadIndex].file?.uuid && (
                        <LocalUploadPreview upload={uploads[activeUploadIndex]} full={true} />
                    )}
                    {uploads[activeUploadIndex].file?.source && (
                        <img
                            src={uploads[activeUploadIndex].file.source}
                            style={{ objectFit: "cover" }}
                        />
                    )}
                    {uploads[activeUploadIndex].id && (
                        <img
                            src={`${API_URL}/uploads/${uploads[activeUploadIndex].id}.json?redirect=1`}
                            style={{ objectFit: "cover" }}
                        />
                    )}
                    {uploads[activeUploadIndex].upload_id && (
                        <img
                            src={`${API_URL}/uploads/${uploads[activeUploadIndex].upload_id}.json?redirect=1`}
                            style={{ objectFit: "cover" }}
                        />
                    )}
                </CustomModal>
            )}
            {uploads.map((upload: any, index: number) => (
                <UploadPreview
                    key={upload.uuid ?? upload.id ?? upload.upload_id}
                    upload={upload}
                    onDeleteUpload={onDeleteUpload}
                    onEditUpload={onEditUpload}
                    setActiveUploadIndex={setActiveUploadIndex}
                    index={index}
                />
            ))}
        </>
    );
};

const CustomModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.95);
    z-index: 106;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${DangerButton} {
        position: fixed;
        top: 10px;
        left: 10px;
    }
`;

const PrevButton = styled(SmallPrimaryButton)`
    position: fixed;
    top: 50%;
    left: 30px;
    z-index: 107;
`;

const NextButton = styled(SmallPrimaryButton)`
    position: fixed;
    top: 50%;
    right: 30px;
    z-index: 107;
`;
