import {
    createEditCalendarEvent,
    deleteFolder,
    deleteFormEntry,
    deleteUpload,
    CREATE_FOLDER_NOTE,
    ADD_FOLDERS,
} from "@rpforms/shared";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { API_URL } from "@rpforms/shared/build/config";
import { translucentColor } from "@rpforms/shared/build/utils";
import { push } from "connected-react-router";
import React, { useState } from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import CustomerInfoModal from "../customers/CustomerInfoModal";
import EntityInfoModal from "../entities/EntityInfoModal";
import EntityStatsModal from "../entities/EntityStatsModal";
import { FolderMover } from "./FolderMover";
import { UploadMover } from "./UploadMover";

const MenuItemWrapper = styled(MenuItem)`
    background: #fff;
    padding: 4px 8px;
    border-bottom: 1px solid #f1f1f1;

    :hover {
        background-color: ${(props) => translucentColor(props.theme.primary_color, 0.1)};
    }
`;

const ContextMenuWrapper = styled(ContextMenu)`
    min-width: 200px;
    border: 1px solid ${(props) => props.theme.primary_color};
    z-index: 20;
    background-color: #fff;
`;

export const FolderContextMenu = ({ actions, folder }) => {
    const dispatch = useDispatch();
    const [settingsModalShow, setSettingsModalShow] = useState(0);
    const [customerModalShow, setCustomerModalShow] = useState(0);
    const [entityInfoModalShow, setEntityInfoModalShow] = useState(0);
    const [mover, setMoverVisible] = useState(false);
    const [folderMover, setFolderMoverVisible] = useState(false);

    const open = () => {
        if (folder.resource_type === "Upload") {
            window.open(`${API_URL}/uploads/${folder.resource.id}.json?redirect=1`);
        } else if (folder.resource_type === "Customer") {
            dispatch(push(`/master_data/customers/view-${folder.resource_id}`));
        } else if (folder.resource_type === "Entity") {
            dispatch(
                push(
                    `/master_data/customers/view-${folder.resource.customer_id}?entity=${folder.resource_id}`
                )
            );
        } else {
            // FormEntry
            dispatch(
                push(
                    `/entries/editor/migrate/source/${folder.form_entry_id}/target/${folder.form_entry_id}`
                )
            );
        }
    };

    const openFolderNoteEditor = () => {
        dispatch(
            openEditor({
                endpoint: `folder_notes`,
                endpointCompany: false,
                new: true,
                preload: { folder },
                type: CREATE_FOLDER_NOTE,
            })
        );
    };

    const createEditTaskClick = (folder: any) => {
        dispatch(createEditCalendarEvent(folder.resource.form_entry_id, true));
        console.log(folder);
    };

    const remove = () => {
        if (confirm("Sind Sie sicher? Diese Aktion kann nicht rückgängig gemacht werden.")) {
            if (folder.resource.form_entry_id && folder.resource.extension === ".pdf") {
                dispatch(
                    deleteFormEntry({ id: folder.resource.form_entry_id }, () => location.reload())
                );
            } else if (folder.resource_type === "Upload") {
                dispatch(
                    deleteUpload(folder.resource, (d) => {
                        location.reload();
                    })
                );
            } else {
                dispatch(
                    deleteFolder(folder.id, (d) => {
                        location.reload();
                    })
                );
            }
        }
    };

    return (
        <ContextMenuWrapper id={`folder-${folder.id}`}>
            {folder.uploadable && (
                <MenuItemWrapper onClick={actions.edit}>Dateien hochladen</MenuItemWrapper>
            )}
            {folder.viewable && <MenuItemWrapper onClick={open}>Ansehen</MenuItemWrapper>}
            {folder.movable && (
                <>
                    <FolderMover folder={folderMover} setVisibility={setFolderMoverVisible} />
                    <UploadMover folder={mover} setVisibility={setMoverVisible} />
                    <MenuItemWrapper
                        onClick={() => {
                            if (["Entity"].includes(folder.resource_type)) {
                                setFolderMoverVisible(folder);
                            } else {
                                setMoverVisible(folder);
                            }
                        }}
                    >
                        Verschieben
                    </MenuItemWrapper>
                </>
            )}
            {folder.removable && <MenuItemWrapper onClick={remove}>Löschen</MenuItemWrapper>}
            {folder.can_create_note && (
                <MenuItemWrapper onClick={() => openFolderNoteEditor()}>
                    Neue Notiz hinzufügen
                </MenuItemWrapper>
            )}
            {folder.migratable && (
                <MenuItemWrapper
                    onClick={() =>
                        dispatch(
                            push(`/entries/editor/entry-edit-${folder.resource.form_entry_id}`)
                        )
                    }
                >
                    Protokoll bearbeiten/verschieben
                </MenuItemWrapper>
            )}
            {folder.editable && (
                <MenuItemWrapper
                    onClick={() =>
                        dispatch(
                            push(`/entries/editor/migrate/source/${folder.resource.form_entry_id}`)
                        )
                    }
                >
                    Protokoll verwenden
                </MenuItemWrapper>
            )}
            {folder.templatable && (
                <MenuItemWrapper
                    onClick={() =>
                        dispatch(
                            push(`/entries/editor/template/source/${folder.resource.form_entry_id}`)
                        )
                    }
                >
                    Protokoll als Vorlage nutzen
                </MenuItemWrapper>
            )}
            {folder.resource_type === "Entity" && (
                <>
                    <EntityStatsModal
                        entity={folder.resource}
                        setSettingsModalShow={setSettingsModalShow}
                        settingsModalShow={settingsModalShow}
                    />
                    <MenuItemWrapper onClick={() => setSettingsModalShow(folder.resource_id)}>
                        Objekt-Statistiken anzeigen
                    </MenuItemWrapper>
                    <EntityInfoModal
                        id={folder.resource_id}
                        setEntityInfoModalShow={setEntityInfoModalShow}
                        entityInfoModalShow={entityInfoModalShow}
                    />
                    <MenuItemWrapper onClick={() => setEntityInfoModalShow(folder.resource_id)}>
                        Objekt-Adresse anzeigen
                    </MenuItemWrapper>
                    {/*<MenuItemWrapper*/}
                    {/*    onClick={() => {*/}
                    {/*        if (*/}
                    {/*            confirm(*/}
                    {/*                `Soll das Objekt ${folder.resource.name} wirklich gelöscht werden?`*/}
                    {/*            )*/}
                    {/*        ) {*/}
                    {/*            dispatch(*/}
                    {/*                deleteEntity(folder.resource, (d) => {*/}
                    {/*                    location.reload();*/}
                    {/*                })*/}
                    {/*            );*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Objekt löschen*/}
                    {/*</MenuItemWrapper>*/}
                </>
            )}
            {folder.parent_resource_type === "Entity" && (
                <>
                    <EntityStatsModal
                        entity={folder.parent_resource}
                        setSettingsModalShow={setSettingsModalShow}
                        settingsModalShow={settingsModalShow}
                        filterByYear={folder.name}
                    />
                    <MenuItemWrapper
                        onClick={() => setSettingsModalShow(folder.parent_resource_id)}
                    >
                        Objekt-Statistiken anzeigen
                    </MenuItemWrapper>
                </>
            )}
            {folder.resource_type === "Customer" && (
                <>
                    <CustomerInfoModal
                        id={folder.resource_id}
                        setCustomerInfoModalShow={setCustomerModalShow}
                        customerInfoModalShow={customerModalShow}
                    />
                    <MenuItemWrapper onClick={() => setCustomerModalShow(folder.resource_id)}>
                        Kunden-Adressen anzeigen
                    </MenuItemWrapper>
                </>
            )}

            {folder.resource_type === "Upload" && folder.resource.ipad && (
                <>
                    <MenuItemWrapper onClick={() => createEditTaskClick(folder)}>
                        Nachbearbeitung erstellen
                    </MenuItemWrapper>
                </>
            )}
        </ContextMenuWrapper>
    );
};
