import {
    createFormEntry,
    deleteFormEntry,
    EDITOR_FORM_ENTRY,
    EDITOR_FORM_ENTRY_CUSTOMER_CHANGED,
    EDITOR_FORM_ENTRY_ENTITY_CHANGED,
    EDITOR_FORM_ENTRY_FORM_CHANGED,
    EDITOR_FORM_ENTRY_FORM_DATA_CHANGED,
    EDITOR_FORM_ENTRY_SET,
    getFormEntry,
    getFormForNewFormEntry,
    updateFormEntryFrontend,
    validateRequiredFields,
} from "@rpforms/shared";
import * as actions from "@rpforms/shared/build/actions/formEntries";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import {
    doNothing,
    findInFields,
    getFormData,
    htmlId,
    isVisible,
    template,
    templateVariables,
} from "@rpforms/shared/build/utils";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import FormEntryFilter from "../../../components/entries/FormEntryFilter";
import { FormRenderer } from "../../../components/FormRenderer";
import { RootState } from "../../../reducers";

const NewEntryPage = () => {
    const formEntry = useSelector<RootState, IFormEntry>((state) => state.formEntries.data);

    const isLoading = useSelector<RootState, IFormEntry>((state) => state.formEntries.isLoading);

    const entities = useSelector<RootState, any>((state) => state.entities.autocomplete);

    const customers = useSelector<RootState, any>((state) => state.customers.autocomplete);

    const [metaUnlocked, setMetaUnlocked] = useState(false);
    const [metaChanged, setMetaChanged] = useState(false);

    const dispatch = useDispatch();

    const { formEntryId } = useParams();

    useEffect(() => {
        // Anything in here is fired on component mount.
        return () => {
            dispatch({ type: EDITOR_FORM_ENTRY });
        };
    }, []);

    useEffect(() => {
        const preload = async () => {
            dispatch(
                getFormEntry(formEntryId, (formEntryData) => {
                    dispatch({
                        type: EDITOR_FORM_ENTRY_SET,
                        payload: formEntryData,
                    });
                })
            );
        };
        if (formEntryId != null) {
            preload();
        } else {
            dispatch({
                type: EDITOR_FORM_ENTRY,
            });
        }
    }, [formEntryId]);

    useEffect(() => {}, [formEntry.form_revision.form_data]);

    const formEntryName = () => {
        if (typeof formEntry.form_revision.form_data === "string") {
            try {
                const fields = JSON.parse(formEntry.form_revision.form_data);
                const configField = findInFields(fields.fields, (f) => {
                    return f.type === "ConfigField";
                });
                if (configField && configField.overrideName) {
                    return template(
                        configField.overridePattern,
                        templateVariables(formEntry),
                        htmlId(formEntry)
                    );
                }
            } catch (e) {}
        }
        return formEntry.name;
    };

    const isNew = () => {
        return formEntry.id === undefined;
    };

    const changeName = (name) => {
        formEntry.name = name;
    };

    const discardFormEntry = () => {
        dispatch({ type: EDITOR_FORM_ENTRY });
        dispatch(push("/entries"));
    };

    const onUpdateCustomer = (option) => {
        setMetaChanged(true);
        const customer = customers.find((c) => c.id === option.value);
        dispatch({
            type: EDITOR_FORM_ENTRY_CUSTOMER_CHANGED,
            payload: {
                customer_id: option.value,
                customer,
            },
        });

        if (isNew()) {
            // really, why do i need this?
            dispatch({
                type: EDITOR_FORM_ENTRY_FORM_CHANGED,
                payload: null,
            });
        }

        dispatch({
            type: EDITOR_FORM_ENTRY_ENTITY_CHANGED,
            payload: {
                entity_id: null,
                entity: null,
            },
        });
    };

    const onUpdateEntity = (option) => {
        setMetaChanged(true);
        const entity = entities.find((e) => e.id === option.value);
        dispatch({
            type: EDITOR_FORM_ENTRY_ENTITY_CHANGED,
            payload: {
                entity_id: option.value,
                entity,
            },
        });
    };

    const onUpdateForm = (option) => {
        if (option.value) {
            dispatch(getFormForNewFormEntry(option.value));
        }
    };

    const onUpdateFormData = (formData) => {
        dispatch({
            type: EDITOR_FORM_ENTRY_FORM_DATA_CHANGED,
            payload: {
                form_data: formData,
                name: formEntryName(),
            },
        });
    };

    const onUpdateUnlockMeta = (metaEnabled) => {
        setMetaUnlocked(metaEnabled);
    };

    const handleEmptyRequiredFields = (requiredFields) => {
        requiredFields.forEach((emptyField) => {
            emptyField.classList.add("myEmpty");
        });
        alert("Der Eintrag ist noch nicht komplett ausgefüllt.");
        return requiredFields[0].scrollIntoView();
    };

    const submitFormEntry = async (mode = "new") => {
        const formRef = document.querySelector("form");
        let requiredFields = Array.from(formRef.querySelectorAll("[required]")).filter((e) =>
            isVisible(e)
        ) as Element[];

        if (!validateRequiredFields(requiredFields, handleEmptyRequiredFields)) {
            return;
        }

        const toastId = displayLoadingToast(mode);
        if (mode === "new" || isNew()) {
            await dispatch(
                createFormEntry(
                    formEntry,
                    (formEntryData) => {
                        dispatch(push("/entries"));
                        if (formEntryData) {
                            displaySuccessToast(toastId, mode);
                        } else {
                            displayErrorToast(toastId);
                        }
                    },
                    () => {
                        displayErrorToast(toastId);
                    }
                )
            );
        } else {
            // return to previous page, we assume everything works and notify
            // the user later if any error occurred.
            history.back();

            await dispatch(
                updateFormEntryFrontend(
                    formEntry.id,
                    getFormData("form-entry-" + formEntry.id),
                    {
                        form_revision_id: formEntry.form_revision_id,
                        form_id: formEntry.form_id,
                        name: formEntry.name,
                        entity_id: formEntry.entity_id,
                    },
                    mode,
                    (fromEntryData) => {
                        if (fromEntryData) {
                            displaySuccessToast(toastId, mode);
                            dispatch({
                                type: EDITOR_FORM_ENTRY,
                            });
                            dispatch(getFormEntry(formEntry.id));
                        } else {
                            displayErrorToast(toastId);
                        }
                    }
                )
            );
        }
    };

    const displayLoadingToast = (mode) => {
        const toastMessage =
            mode === "override"
                ? "Eintrag wird überschrieben..."
                : "Neuer Eintrag wird erstellt...";

        return toast(toastMessage, { autoClose: false });
    };

    const displaySuccessToast = (toastId, mode) => {
        toast.update(toastId, {
            render: `🦄 Eintrag ${
                mode === "override" ? "überschrieben." : "erstellt. Bitte warten..."
            }`,
            type: toast.TYPE.SUCCESS,
            position: "top-right",
            autoClose: 2000,
        });
    };

    const displayErrorToast = (toastId) => {
        toast.update(toastId, {
            render: "😟 Ein Fehler ist unterlaufen, bitte versuchen Sie es erneut..",
            type: toast.TYPE.ERROR,
            position: "top-right",
            autoClose: 2000,
        });
    };

    const removeEntry = async () => {
        if (confirm("Möchten Sie diesen Eintrag entfernen?")) {
            await dispatch(deleteFormEntry(formEntry));
            await dispatch(push("/entries"));
        }
    };

    const metaLockButtonStatus = () => {
        if (isNew()) {
            return "hidden";
        } else {
            return metaChanged ? "readable" : "editable";
        }
    };

    const formIsTemplatable = () => {
        return formEntry?.form?.templatable_form_entry_creation;
    };

    return (
        <>
            {!isNew() && (
                <PageNavbar
                    rename={false}
                    onRename={changeName}
                    title={`Editor`}
                    description={
                        "Hier werden Formulare bearbeitet oder zwischen Kunden/Objekten bewegt"
                    }
                    subtitle={"Bearbeiten/Verschieben"}
                >
                    <PageNavAction
                        data-back-button
                        onClick={() => window.history.back()}
                        icon="chevron-left"
                        title="Zurück"
                    />
                    <PageNavAction
                        onClick={discardFormEntry}
                        danger={true}
                        title="Verwerfen"
                        icon="trash"
                    />

                    {!metaUnlocked && (
                        <>
                            <PageNavAction
                                onClick={() => submitFormEntry("new")}
                                success={formIsTemplatable()}
                                disabled={!formIsTemplatable()}
                                icon="save"
                                title="Speichern (neuer Eintrag)"
                                hint={
                                    formIsTemplatable()
                                        ? "Speichern"
                                        : "für diesen Formular-Typ nicht verfügbar"
                                }
                            />
                            <PageNavAction
                                onClick={() => submitFormEntry("override")}
                                danger={true}
                                icon="save"
                                title="Überschreiben"
                            />
                            <PageNavAction
                                onClick={removeEntry}
                                danger={true}
                                icon="trash"
                                title=""
                                hint="löschen"
                            />
                        </>
                    )}
                    {metaUnlocked && (
                        <>
                            <PageNavAction
                                onClick={() => window.location.reload()}
                                danger={true}
                                icon="trash"
                                title="Verwerfen"
                            />
                            <PageNavAction
                                onClick={() => submitFormEntry("override")}
                                success={true}
                                icon="save"
                                title="Verschieben"
                            />
                        </>
                    )}
                </PageNavbar>
            )}
            {isNew() && (
                <PageNavbar
                    rename={false}
                    onRename={changeName}
                    title={`Editor`}
                    description={"Hier werden Formulare neu erstellt"}
                    subtitle={"Anlegen"}
                >
                    <PageNavAction
                        data-back-button={"new"}
                        onClick={() => window.history.back()}
                        icon="chevron-left"
                        title="Zurück"
                    />
                    {!isLoading && (
                        <PageNavAction
                            onClick={discardFormEntry}
                            danger={true}
                            title="Verwerfen"
                            icon="trash"
                        />
                    )}
                    {!isLoading && (
                        <PageNavAction
                            onClick={submitFormEntry}
                            success={true}
                            icon="save"
                            title="Speichern (neuer Eintrag)"
                        />
                    )}
                </PageNavbar>
            )}
            <FormEntryFilter
                onUpdateCustomer={onUpdateCustomer}
                onUpdateEntity={onUpdateEntity}
                onUpdateForm={onUpdateForm}
                onUpdateFormEntry={doNothing}
                onUpdateCustomerAndEntityLock={onUpdateUnlockMeta}
                formIncludeAllOption={false}
                formEntry={formEntry}
                customerAndEntity={metaLockButtonStatus()}
                hideFormSelector={!isNew()}
            />
            {isLoading && <Loader />}
            {!isLoading && formEntry && (
                <FormRenderer
                    onSubmit={doNothing}
                    onChange={onUpdateFormData}
                    name={formEntry.name}
                    formEntry={formEntry}
                />
            )}
        </>
    );
};

function mapStateToProps(state) {
    return { device: state.device, formEntries: state.formEntries };
}

export default withRouter(connect(mapStateToProps, actions)(NewEntryPage));
