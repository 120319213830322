import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { downloadZIPRequest, getRevenues } from "@rpforms/shared";
import { SmallButton, SmallPrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import { RootState } from "../../reducers";
import FinancesInvoiceTypeSelector from "./FinancesInvoiceTypeSelector";
import { FinancesOfferTypeSelector } from "./FinancesOfferTypeSelector";
import ListInvoices from "./ListInvoices";
import ListOffers from "./ListOffers";
import { BigAmount, Title, TitleContainer } from "./TotalOverview";

const FinancesCustomerDetails = ({
    maxDate,
    type,
}: {
    maxDate: number;
    type: "offer" | "invoice";
}) => {
    const [invoiceTypeLabel, setInvoiceTypeLabel] = useState("Gesamt");
    const [zipLoading, setZipLoading] = useState(false);
    const zip = useSelector<RootState, any>((state) => state.formEntries.zip);

    const {
        total,
        repair_count,
        disposal_count,
        service_count,
        isLoading,
        isTotalLoading,
        customer,
        customer_id,
        entity,
        entity_id,
        totalSalesPriceFormatted,
        totalNetSalesPriceFormatted,
        totalPaidSalesPriceFormatted,
        totalNetPaidSalesPriceFormatted,
        repairSalesPriceFormatted,
        repairNetSalesPriceFormatted,
        repairPaidSalesPriceFormatted,
        repairNetPaidSalesPriceFormatted,
        disposalSalesPriceFormatted,
        disposalNetSalesPriceFormatted,
        disposalPaidSalesPriceFormatted,
        disposalNetPaidSalesPriceFormatted,
        serviceSalesPriceFormatted,
        serviceNetSalesPriceFormatted,
        servicePaidSalesPriceFormatted,
        serviceNetPaidSalesPriceFormatted,
        invoiceType,
        selectedYear,
        selectedMonth,
        selectedDay,
        form_entry_ids,
        disposal_form_entry_ids,
        repair_form_entry_ids,
        service_form_entry_ids,
        zipUrl,
        isNet,
    } = useSelector<RootState, any>((state) => state.finances);

    const dispatch = useDispatch();

    useEffect(() => {
        if (customer_id && maxDate) {
            dispatch(
                getRevenues(
                    null,
                    customer_id,
                    entity_id,
                    selectedYear || new Date().getFullYear(),
                    selectedMonth,
                    selectedDay,
                    (type + "s") as any // sorry. invoice -> invoices, offer -> offers
                )
            );
        }
    }, [customer_id, entity_id]);

    useEffect(() => {
        switch (invoiceType) {
            case 0:
                return setInvoiceTypeLabel("Gesamt");
            case 1:
                if (type === "offer") {
                    return setInvoiceTypeLabel("Beauftragt");
                }
                return setInvoiceTypeLabel("Verkauf");
            case 2:
                if (type === "offer") {
                    return setInvoiceTypeLabel("Nicht beauftragt");
                }
                return setInvoiceTypeLabel("Reparatur");
            case 3:
                return setInvoiceTypeLabel("Service");
            case 4:
                return setInvoiceTypeLabel("Beauftragt & Ausstehend");
        }
    }, [invoiceType]);

    useEffect(() => {
        if (zipUrl != null) {
            setZipLoading(false);
        }
    }, [zipUrl]);

    const invoiceTypeSlug = () => {
        const m = {
            1: "disposal",
            2: "repair",
            3: "service",
            4: "total",
            0: "total",
        };
        return m[invoiceType] || "total";
    };

    const scopedFormEntryIds = () => {
        switch (invoiceTypeSlug()) {
            case "disposal":
                return disposal_form_entry_ids;
            case "repair":
                return repair_form_entry_ids;
            case "service":
                console.log(service_form_entry_ids);
                return service_form_entry_ids;
        }
        return form_entry_ids;
    };

    const salesPrice = (paid = false) => {
        switch (invoiceType) {
            case 0:
                return getTotalSalesPrice(paid);
            case 1:
                return getDisposalSalesPrice(paid);
            case 2:
                return getRepairSalesPrice(paid);
            case 3:
                return getServiceSalesPrice(paid);
            case 4:
                return isNet ? totalNetSalesPriceFormatted : totalSalesPriceFormatted;
        }
        return getTotalSalesPrice(paid);
    };

    const getTotalSalesPrice = (paid = false) => {
        if (paid) {
            return isNet ? totalNetPaidSalesPriceFormatted : totalPaidSalesPriceFormatted;
        }
        return isNet ? totalNetSalesPriceFormatted : totalSalesPriceFormatted;
    };

    const getDisposalSalesPrice = (paid = false) => {
        if (paid) {
            return isNet ? disposalNetPaidSalesPriceFormatted : disposalPaidSalesPriceFormatted;
        }
        return isNet ? disposalNetSalesPriceFormatted : disposalSalesPriceFormatted;
    };

    const getRepairSalesPrice = (paid = false) => {
        if (paid) {
            return isNet ? repairNetPaidSalesPriceFormatted : repairPaidSalesPriceFormatted;
        }
        return isNet ? repairNetSalesPriceFormatted : repairSalesPriceFormatted;
    };

    const getServiceSalesPrice = (paid = false) => {
        if (paid) {
            return isNet ? serviceNetPaidSalesPriceFormatted : servicePaidSalesPriceFormatted;
        }
        return isNet ? serviceNetSalesPriceFormatted : serviceSalesPriceFormatted;
    };

    let countProperty = total;
    switch (invoiceTypeSlug()) {
        case "disposal":
            countProperty = disposal_count;
            break;
        case "repair":
            countProperty = repair_count;
            break;
        case "service":
            countProperty = service_count;
            break;
    }

    return (
        <>
            <Row style={{ marginTop: "2rem" }}>
                <Col>
                    {type === "invoice" ? (
                        <FinancesInvoiceTypeSelector maxDate={maxDate} />
                    ) : (
                        <FinancesOfferTypeSelector maxDate={maxDate} />
                    )}
                </Col>
            </Row>

            <Row>
                <Col>
                    {customer ? (
                        <h3>
                            {customer.name}
                            {entity && ` - ${entity.name}`}
                            {selectedYear && ` - ${selectedYear}`}
                            {selectedMonth && `-${selectedMonth}`}
                            {selectedDay && `-${selectedDay}`}
                        </h3>
                    ) : (
                        <h3>
                            Gesamtübersicht{selectedYear && ` - ${selectedYear}`}
                            {selectedMonth && `-${selectedMonth}`}
                            {selectedDay && `-${selectedDay}`}
                        </h3>
                    )}
                </Col>
            </Row>

            {isLoading || isTotalLoading ? (
                <Row>
                    <Col style={{ minHeight: "390px" }}>
                        <Row style={{ minHeight: "50px" }}>
                            <Col>
                                <span style={{ marginRight: "1rem" }}>Lade Umsätze...</span>
                                <Spinner animation="border" size="sm" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Row style={{ minHeight: "390px" }}>
                    <Col>
                        <Row style={{ minHeight: "50px" }}>
                            <Col>
                                <TitleContainer>
                                    <Title medium style={{ marginRight: "1rem" }}>
                                        Umsätze {`(${invoiceTypeLabel})`}:
                                    </Title>

                                    <BigAmount medium>{salesPrice(false)}</BigAmount>
                                    {type === "invoice" && (
                                        <>
                                            <h3
                                                style={{
                                                    margin: "0 1rem",
                                                    fontSize: "18px",
                                                    fontWeight: "lighter",
                                                }}
                                            >
                                                /
                                            </h3>
                                            <BigAmount medium color="green">
                                                {salesPrice(true)}
                                            </BigAmount>
                                        </>
                                    )}
                                </TitleContainer>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <TitleContainer style={{ maxWidth: "500px" }}>
                                    <Title small style={{ marginTop: "3rem" }}>
                                        Anzahl {type === "invoice" ? "Rechnungen" : "Angebote"}:
                                    </Title>
                                    <span style={{ marginLeft: "2rem" }}>{countProperty}</span>
                                    <span
                                        style={{
                                            textAlign: "right",
                                            width: "100%",
                                        }}
                                    >
                                        {!zipLoading && type === "invoice" && (
                                            <a
                                                title={
                                                    zipUrl == null
                                                        ? "ZIP-Datei anfordern"
                                                        : "ZIP-Datei herunterladen"
                                                }
                                                href={zipUrl}
                                                onClick={() => {
                                                    if (zipUrl == null) {
                                                        if (
                                                            confirm(
                                                                "Möchten Sie das ZIP für die ausgewählte Anzahl der Protokolle generieren?"
                                                            )
                                                        ) {
                                                            setZipLoading(true);
                                                            dispatch(
                                                                downloadZIPRequest(
                                                                    scopedFormEntryIds().join(",")
                                                                )
                                                            );
                                                            return true;
                                                        }
                                                    }
                                                    return false;
                                                }}
                                                download={"PDF.zip"}
                                            >
                                                {zipUrl == null && (
                                                    <SmallButton>
                                                        <i className="fa fa-file-zip-o"></i>{" "}
                                                        ZIP-Datei anfordern
                                                    </SmallButton>
                                                )}
                                                {zipUrl != null && (
                                                    <SmallPrimaryButton>
                                                        <i className="fa fa-file-zip-o"></i>{" "}
                                                        ZIP-Datei herunterladen
                                                    </SmallPrimaryButton>
                                                )}
                                            </a>
                                        )}
                                        {zipLoading && zipUrl == null && <>{zip.message}</>}
                                    </span>
                                </TitleContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {type === "offer" ? (
                                    <ListOffers maxDate={maxDate} />
                                ) : (
                                    <ListInvoices />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default FinancesCustomerDetails;
